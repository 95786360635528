@import url(https://fonts.googleapis.com/css?family=Lato);
$font-family-sans-serif: 'Lato', sans-serif;
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.pdf {
  padding-top: 1em;
  a, a:hover {
    color: #002277;
    text-decoration:none;
  }
}
.overlay {
  position: relative;
  z-index: 99;
  font-family: Helvetica;
  background-color: white;
  width: 100%;
  margin: 0 auto;
  border-radius: 1em;
  padding: 1em;
  text-align: center;
  color: #002277;
  -webkit-box-shadow: 4px -4px 0px 7px #002277;
  -moz-box-shadow: 4px -4px 0px 7px #002277;
  box-shadow: 4px -4px 0px 7px #002277;
  h1, h2, h3 {
    font-weight: 600;
    // line-height: 0.8em;
    margin: 0.3em;
    .gender {
      font-size: 66%;
    }
  }
  h1 {
    font-size: 4vw;
    font-weight: 800;
  }
  h2 {
    font-size: 2.5vw;
  }
  h3 {
    font-size: 1.5vw;
  }
  .boxes {
    margin-top: 20px;
    text-align: left;
    .box {
      ul {
        padding-left: 1.5em;
      }
    }
  }
}
h4 {
  font-weight: 900;
}
.weihenstephan {
  background-color: #024079;
  color: white;
  -webkit-box-shadow: 4px -4px 0px 7px #667caa;
  -moz-box-shadow: 4px -4px 0px 7px #667caa;
  box-shadow: 4px -4px 0px 7px #667caa;
}
@media (min-width: $screen-sm-min){
    .cell-box:nth-child(1n+1){
        clear: both;
    }
}
@media (min-width: $screen-md-min){
    .cell-box:nth-child(1n+1){
        clear: none;
    }
    .cell-box:nth-child(2n+1){
        clear: both;
    }
}
@media (min-width: $screen-lg-min){
    .cell-box:nth-child(2n+1){
        clear: none;
    }
    .cell-box:nth-child(3n+1){
        clear: both;
    }
}
.je {
  padding:3em 1em;
  color:white;
  background-color: #002277;
}
.imprint {
  color: black;
}
.cloud {
  position: absolute;
  height: 90%;
  overflow: hidden;
  line-height: 65%;
  span {
    font-size: 80%;
    text-transform: uppercase;
    word-break: break-all;
    word-wrap: break-word;
    color: #667caa;
    &:nth-of-type(2n) {
      font-weight: bold;
      color: #002277;
    }
  }
}
.overlaycontainer {
  display: none;
  z-index: 98;
  position: fixed;
  top: 0;
  left: 0;
  padding: 5% 10%;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  overflow: scroll;
}
.closebutton {
  position: fixed;
  z-index: 100;
  right: 1em;
  top: 1em;
  cursor: pointer;
  img {
    width: 40px;
  }
}
.toclick {
  cursor: pointer;
}
.done {
  position: absolute;
  top: 0;
  right: 1em;
  width: 100px;
  height: 100px;
  background-image: url('../img/vermittelt.png');
  background-size: contain;
}
.startcontainer {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url('../img/skyline.jpg');
  background-position: center top;
  background-size: cover;
}
.startlogo {
  position: fixed;
  bottom: 10%;
  height: 120px;
  width: 100%;
  background-color: white;
  text-align: center;
  padding: 10px;
}
